import { TopLevelToken } from "../../shared/types";
import { makeParagraph } from "../model/defaults";

export function markConflictingEdits(
  localTokens: TopLevelToken[],
  remoteTokens: TopLevelToken[],
  source: "import" | "server" = "server",
) {
  return [
    makeParagraph("/!\\ Conflict! Simply edit this note to fix it"),
    ...localTokens,
    makeParagraph("/!\\ Here is the version from the " + source),
    ...remoteTokens,
  ];
}

export function markConflictingDelete(
  localTokens: TopLevelToken[],
  source: "import" | "server" = "server",
): TopLevelToken[] {
  return [
    makeParagraph("/!\\ Conflict! Simply edit this note to fix it"),
    ...localTokens,
    makeParagraph(`/!\\ the version from the ${source} has been deleted`),
  ];
}

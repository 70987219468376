import { defaultNoteFields, makeSettledPosition } from "../../model/defaults";
import { generateId } from "../../model/generateId";
import { Folder, Note } from "../../../shared/types";
import { ONBOARDING_GUIDE_URL } from "../../utils/constants";
import { getUserId } from "../../model/atoms";

export const getDefaultNotes = (
  graphId: string | undefined,
): {
  id: string;
  notes: Note[];
  folders: Folder[];
} => {
  if (!graphId) {
    throw new Error("cannot getDefaultThoughtstream, undefined graphId");
  }

  const userId = getUserId() || null;

  const thoughtstreamId = graphId;
  const noteIds = Array.from({ length: 8 }, () => generateId());

  return {
    id: thoughtstreamId,
    folders: [],
    notes: [
      {
        id: noteIds[0],
        authorId: userId,
        tokens: [
          {
            tokenId: generateId(),
            type: "paragraph",
            content: [
              {
                type: "text",
                marks: ["bold"],
                content: "Welcome to your second brain, ",
              },
              {
                type: "spaceship",
                linkedNoteId: noteIds[5],
                tokenId: generateId(),
              },
              {
                type: "text",
                marks: [],
                content: "!",
              },
            ],
          },
          {
            tokenId: generateId(),
            type: "paragraph",
            content: [
              {
                type: "text",
                marks: [],
                content: "💡 Check out our Onboarding Quickstart Guide ",
              },
              {
                type: "link",
                content: ONBOARDING_GUIDE_URL,
                slug: ONBOARDING_GUIDE_URL,
              },
              {
                type: "text",
                marks: [],
                content: " ",
              },
            ],
          },
        ],
        position: makeSettledPosition("aC"),
        ...defaultNoteFields,
      },
      {
        id: noteIds[1],
        authorId: userId,
        tokens: [
          {
            tokenId: generateId(),
            type: "paragraph",
            content: [
              {
                type: "text",
                marks: [],
                content:
                  "Here are a few quick tips and tricks for navigating your notebook, or as we like to call it, your ",
              },
              {
                type: "text",
                marks: ["italic"],
                content: "ThoughtStream",
              },
              {
                type: "text",
                marks: [],
                content: ". It's an infinitely long text file, with notes separated by dividers. 💡",
              },
            ],
          },
        ],
        position: makeSettledPosition("aC8"),
        ...defaultNoteFields,
      },
      {
        id: noteIds[2],
        authorId: userId,
        tokens: [
          {
            tokenId: generateId(),
            type: "paragraph",
            content: [
              {
                type: "text",
                marks: [],
                content: "To add a new entry to the top of your Thoughtstream, press cmd+k (or ctrl+k on Windows)",
              },
            ],
          },
        ],
        position: makeSettledPosition("aCG"),
        ...defaultNoteFields,
      },
      {
        id: noteIds[3],
        authorId: userId,
        tokens: [
          {
            tokenId: generateId(),
            type: "paragraph",
            content: [
              {
                type: "text",
                marks: [],
                content: "#️⃣ ",
              },
              {
                type: "text",
                marks: ["bold"],
                content: "Hashtags",
              },
              {
                type: "text",
                marks: [],
                content: " like ",
              },
              {
                type: "hashtag",
                content: "#book",
              },
              {
                type: "text",
                marks: [],
                content: " are used to tag notes into categories. Some tags we can suggest: ",
              },
              {
                type: "hashtag",
                content: "#quote",
              },
              {
                type: "text",
                marks: [],
                content: " ",
              },
              {
                type: "hashtag",
                content: "#book",
              },
              {
                type: "text",
                marks: [],
                content: " ",
              },
              {
                type: "hashtag",
                content: "#idea",
              },
              {
                type: "text",
                marks: [],
                content: " ",
              },
              {
                type: "hashtag",
                content: "#restaurant",
              },
              {
                type: "text",
                marks: [],
                content: " ",
              },
              {
                type: "hashtag",
                content: "#motivation",
              },
              {
                type: "text",
                marks: [],
                content: " ",
              },
              {
                type: "hashtag",
                content: "#insight",
              },
            ],
          },
          {
            type: "list",
            content: [
              {
                type: "listItem",
                content: [
                  {
                    tokenId: generateId(),
                    type: "paragraph",
                    content: [
                      {
                        type: "text",
                        marks: [],
                        content: "Then create as many topic or project-specific tags as you’d like, such as ",
                      },
                      {
                        type: "hashtag",
                        content: "#AI",
                      },
                      {
                        type: "text",
                        marks: [],
                        content: " ",
                      },
                      {
                        type: "hashtag",
                        content: "#TaoistPhilosophy",
                      },
                      {
                        type: "text",
                        marks: [],
                        content: " ",
                      },
                      {
                        type: "hashtag",
                        content: "#ProductLaunchBlogPost",
                      },
                    ],
                  },
                ],
                depth: 0,
              },
            ],
          },
          {
            tokenId: generateId(),
            type: "paragraph",
            content: [],
          },
          {
            tokenId: generateId(),
            type: "paragraph",
            content: [
              {
                type: "text",
                marks: [],
                content: "🔗 ",
              },
              {
                type: "text",
                marks: ["bold"],
                content: "Relations,",
              },
              {
                type: "text",
                marks: [],
                content: " accessible through the “+” key, allow you to link, display, and edit related notes",
              },
            ],
          },
          {
            type: "list",
            content: [
              {
                type: "listItem",
                content: [
                  {
                    tokenId: generateId(),
                    type: "paragraph",
                    content: [
                      {
                        type: "text",
                        marks: [],
                        content: "Use to connect ideas, quotes, insights – or to tag people & companies",
                      },
                    ],
                  },
                ],
                depth: 0,
              },
              {
                type: "listItem",
                content: [
                  {
                    tokenId: generateId(),
                    type: "paragraph",
                    content: [
                      {
                        type: "text",
                        marks: [],
                        content: "See also: ",
                      },
                      {
                        type: "spaceship",
                        linkedNoteId: noteIds[4],
                        tokenId: generateId(),
                      },
                      {
                        type: "text",
                        marks: [],
                        content: " ",
                      },
                    ],
                  },
                ],
                depth: 0,
              },
            ],
          },
        ],
        position: makeSettledPosition("aCO"),
        ...defaultNoteFields,
      },
      {
        id: noteIds[4],
        authorId: userId,
        tokens: [
          {
            tokenId: generateId(),
            type: "paragraph",
            content: [
              {
                type: "text",
                marks: [],
                content: "Thoughts on Relations",
              },
            ],
          },
          {
            type: "list",
            content: [
              {
                type: "listItem",
                content: [
                  {
                    tokenId: generateId(),
                    type: "paragraph",
                    content: [
                      {
                        type: "text",
                        marks: [],
                        content:
                          "Relations unlock a new paradigm in note-taking and memory augmentation, helping elevate your digital knowledge-base to a network that reveals powerful insights",
                      },
                    ],
                  },
                ],
                depth: 0,
              },
            ],
          },
        ],
        position: makeSettledPosition("aCV"),
        ...defaultNoteFields,
      },
      {
        id: noteIds[5],
        authorId: userId,
        tokens: [
          {
            tokenId: generateId(),
            type: "paragraph",
            content: [
              {
                type: "text",
                marks: ["bold"],
                content: "Ideaflow",
              },
            ],
          },
          {
            tokenId: generateId(),
            type: "paragraph",
            content: [],
          },
          {
            type: "list",
            content: [
              {
                type: "listItem",
                content: [
                  {
                    tokenId: generateId(),
                    type: "paragraph",
                    content: [
                      {
                        type: "text",
                        marks: [],
                        content: "Seed-stage startup backed by ",
                      },
                      {
                        type: "spaceship",
                        linkedNoteId: noteIds[7],
                        tokenId: generateId(),
                      },
                      {
                        type: "text",
                        marks: [],
                        content: " and ",
                      },
                      {
                        type: "spaceship",
                        linkedNoteId: noteIds[6],
                        tokenId: generateId(),
                      },
                    ],
                  },
                ],
                depth: 0,
              },
              {
                type: "listItem",
                content: [
                  {
                    tokenId: generateId(),
                    type: "paragraph",
                    content: [
                      {
                        type: "text",
                        marks: [],
                        content: "An AI-powered notebook for digital notetakers looking to augment their memory",
                      },
                    ],
                  },
                ],
                depth: 0,
              },
            ],
          },
        ],
        position: makeSettledPosition("aCd"),
        ...defaultNoteFields,
      },
      {
        id: noteIds[6],
        authorId: userId,
        tokens: [
          {
            tokenId: generateId(),
            type: "paragraph",
            content: [
              {
                type: "text",
                marks: [],
                content: "8VC",
              },
            ],
          },
          {
            tokenId: generateId(),
            type: "paragraph",
            content: [
              {
                type: "text",
                marks: [],
                content: "Investor based in SF",
              },
            ],
          },
        ],
        position: makeSettledPosition("aCl"),
        ...defaultNoteFields,
      },
      {
        id: noteIds[7],
        authorId: userId,
        tokens: [
          {
            tokenId: generateId(),
            type: "paragraph",
            content: [
              {
                type: "text",
                marks: [],
                content: "First Round",
              },
            ],
          },
          {
            tokenId: generateId(),
            type: "paragraph",
            content: [
              {
                type: "text",
                marks: [],
                content: "Seed investor, great partner of ",
              },
              {
                type: "spaceship",
                linkedNoteId: noteIds[5],
                tokenId: generateId(),
              },
            ],
          },
        ],
        position: makeSettledPosition("aCt"),
        ...defaultNoteFields,
      },
    ],
  };
};
